<template>
  <div>
    <Modal v-model="addParkModal" width="800"></Modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      addParkModal: false
    }
  }
}
</script>
